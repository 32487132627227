import React from 'react';

const ProgressBar = ({ elapsedTime, totalDuration }) => {
    const progress = Math.min(100, (elapsedTime / totalDuration) * 100);
    const remaining = Math.max(0, totalDuration - elapsedTime);

    if (progress >= 100) {
        return null;
    }

    const minutes = Math.floor(remaining / 60);
    const seconds = Math.floor(remaining % 60);
    const formattedTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;

    return (
        <div className="px-4 py-2 bg-gray-50 border-b">
            <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                    className="bg-blue-500 h-2 rounded-full transition-all duration-100"
                    style={{ width: `${progress}%` }}
                />
            </div>

            <div className="flex justify-between mt-1 text-xs text-gray-500">
                <span>{Math.round(progress)}% complete</span>
                <span>{formattedTime} remaining</span>
            </div>
        </div>
    );
};

export default ProgressBar;