import React from 'react';
import { users } from '../constants/users';

const ChatMessage = ({ content, user, time }) => {
    const isSystem = user === 'system';
    const userData = users[user] || {};
    
    if (isSystem) {
        return (
            <div className="flex justify-center my-3">
                <div className="bg-gradient-to-r from-gray-100 to-gray-50 rounded-full px-4 py-2 text-xs text-gray-600 shadow-sm">
                    {content}
                </div>
            </div>
        );
    }

    return (
        <div className={`flex items-start gap-3 group mb-4 ${userData.alignClass}`}>
            <div className="relative">
                <div className={`
                    w-10 h-10 rounded-full 
                    flex items-center justify-center text-lg
                    shadow-md transition-transform group-hover:scale-105
                    ${userData.color}
                `}>
                    {userData.avatar}
                </div>
                <div className={`
                    absolute bottom-0 right-0 
                    w-3 h-3 rounded-full border-2 border-white
                    ${userData.statusColor}
                `} />
            </div>

            <div className="flex flex-col max-w-[70%]">
                <div className="flex items-baseline gap-2 mb-1">
                    <span className="font-semibold text-sm">{userData.name}</span>
                    <span className="text-[11px] text-gray-400">{time}</span>
                </div>
                <div className={`
                    p-3 rounded-2xl shadow-sm
                    transform transition-all duration-200
                    group-hover:shadow-md group-hover:-translate-y-0.5
                    ${userData.messageBg}
                    ${userData.messageText}
                `}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default ChatMessage;