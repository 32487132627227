import { useState, useEffect } from 'react';

export const useTeamFeedbackData = () => {
    const [feedbacks, setFeedbacks] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadFeedback = async () => {
            try {
                const response = await fetch('/data/team_Feedback_1_Pink_1_20241209_194605.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch team feedback data');
                }
                
                const data = await response.json();
                if (!data.team_feedback || !Array.isArray(data.team_feedback)) {
                    throw new Error('Invalid team feedback data format');
                }

                const processedFeedbacks = data.team_feedback.map(item => ({
                    prompt_number: item.prompt_number,
                    prompt_name: item.prompt_name,
                    content: item.feedback,
                    metrics: item.metrics_used || {}
                }));

                processedFeedbacks.sort((a, b) => a.prompt_number - b.prompt_number);
                
                setFeedbacks(processedFeedbacks);
            } catch (err) {
                console.error('Error loading team feedback:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        loadFeedback();
    }, []);

    return { feedbacks, loading, error };
};