import React from 'react';

const ProjectInfoPage = ({ onContinue }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <div className="max-w-3xl w-full bg-white rounded-xl shadow-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Welcome to Our Formative Study</h1>
        
        <div className="space-y-6 text-gray-600">
          <p className="text-lg">
            Thank you for participating in this formative study. We are conducting research to evaluate nine different types of feedback generated using distinct prompts.
          </p>

          <div className="bg-blue-50 p-6 rounded-lg border border-blue-100">
            <h2 className="text-xl font-semibold text-blue-900 mb-4">What to Expect</h2>
            <ul className="list-disc list-inside space-y-3">
              <li>You will observe a 12-minute pre-recorded scenario of a survival discussion</li>
              <li>Your role is to read the conversation taking the perspective of the <span className="text-green-600 font-medium">Green</span> participant</li>
              <li>At the end of the conversation, you will see the final ranked list of items</li>
              <li>You will receive nine different types of feedback</li>
              <li>Your task is to evaluate these feedback types based on criteria that would help improve future performance</li>
            </ul>
          </div>

          <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Your Role</h2>
            <p>As the <span className="text-green-600 font-medium">Green</span> participant, you will:</p>
            <ul className="list-disc list-inside space-y-2 mt-2">
              <li>Follow the conversation flow</li>
              <li>Understand team dynamics</li>
              <li>Observe decision-making processes</li>
              <li>Evaluate the effectiveness of different feedback types</li>
            </ul>
          </div>
        </div>

        <button
          onClick={onContinue}
          className="mt-8 w-full bg-blue-600 text-white py-3 px-6 rounded-lg
                     hover:bg-blue-700 transition-colors duration-200
                     flex items-center justify-center gap-2"
        >
          Continue to Discussion
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ProjectInfoPage; 