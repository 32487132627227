import React from 'react';
import { motion } from 'framer-motion';

const CompletionPage = ({ userName }) => {
    return (
        <div className="min-h-screen bg-gray-50 p-4">
            <div className="max-w-4xl mx-auto">
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-white rounded-xl shadow-lg p-8"
                >
                    <h1 className="text-3xl font-bold text-gray-900 mb-6">
                        Thank You for Your Participation!
                    </h1>
                    <div className="bg-green-50 border border-green-100 rounded-lg p-6 mb-6">
                        <h2 className="text-xl font-semibold text-green-900 mb-4">
                            Study Complete
                        </h2>
                        <p className="text-green-800 mb-4">
                            Thank you {userName} for completing the feedback evaluation study.
                        </p>
                        <p className="text-green-800">
                            Your Amazon gift card will be sent to you within 3 weeks.
                        </p>
                    </div>
                    <div className="bg-blue-50 border border-blue-100 rounded-lg p-6">
                        <h2 className="text-xl font-semibold text-blue-900 mb-4">
                            Questions or Concerns?
                        </h2>
                        <p className="text-blue-800">
                            If you need any additional information or have questions, please contact:{' '}
                            <a 
                                href="mailto:malmutai@nd.edu" 
                                className="text-blue-600 hover:text-blue-700 underline"
                            >
                                malmutai@nd.edu
                            </a>
                        </p>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default CompletionPage; 