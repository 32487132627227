import React, { useRef, useEffect } from 'react';
import ChatMessage from './ChatMessage';
import TypingIndicator from './TypingIndicator';

const ChatTimeline = ({ messages = [], typingUser = null }) => {
    const messagesEndRef = useRef(null);
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <div className="flex-1 overflow-y-auto">
            <div className="min-h-full bg-gradient-to-b from-gray-50 to-white px-6 py-4">

                <div className="text-center mb-8">
                    <div className="inline-block bg-gradient-to-r from-blue-500 to-blue-600 rounded-full px-6 py-2 text-sm text-white shadow-lg">
                        Winter Survival Discussion Started
                    </div>
                </div>


                <div className="space-y-6">
                    {messages.map((message, index) => (
                        <ChatMessage
                            key={index}
                            {...message}
                        />
                    ))}
                </div>

                {typingUser && (
                    <div className="mt-4 animate-fade-in">
                        <TypingIndicator user={typingUser} />
                    </div>
                )}

                <div ref={messagesEndRef} />
            </div>
        </div>
    );
};

export default ChatTimeline;