export const EVALUATION_CRITERIA = [
    {
        id: 1,
        text: "Clarity and Understanding",
        explanation: "The feedback was clear, concise, and easy to understand.",
        subExplanation: "Evaluate whether the feedback was written in a straightforward and concise manner. Did the wording make the feedback simple to interpret, or were there parts that seemed confusing or unclear?"
    },
    {
        id: 2,
        text: "Satisfaction",
        explanation: "The feedback effectively addressed strengths and areas for improvement to enhance performance in future tasks based on my performance.",
        subExplanation: "This statement asks if the feedback provided addressed the strengths and areas where you can improve. Did it meet your expectations and give you useful insights that make you feel satisfied with the feedback you received?"
    },
    {
        id: 3,
        text: "Level of Detail",
        explanation: "The feedback provided me sufficient detail to help you improve my performance.",
        subExplanation: "This statement asks whether the feedback contained enough information for you to understand how to improve. Did it give you the right amount of detail to help you take action, or did it feel too vague or overwhelming?"
    },
    {
        id: 4,
        text: "Completeness",
        explanation: "The feedback covered all the relevant and important aspects of the task that necessary to improve in similar future task.",
        subExplanation: "This statement is about whether the feedback covered all the important aspects of your performance. Do you feel like nothing was left out? Good feedback should be thorough and address everything relevant to your task."
    },
    {
        id: 5,
        text: "Accuracy and Reliability",
        explanation: "The feedback accurately reflected my strengths and weaknesses.",
        subExplanation: "You're being asked to evaluate whether the feedback correctly reflected your performance, did it accurately highlight your strengths and weaknesses? Also, think about whether the feedback was consistent with what you experienced during the task. Reliable feedback should feel truthful and valid."
    },
    {
        id: 6,
        text: "Actionability",
        explanation: "The feedback provided clear and practical steps for improvement.",
        subExplanation: "This statement focuses on whether the feedback gave you specific and practical steps you can take to improve. Think about whether the feedback gave you clear instructions or ideas that you can actually use to make progress."
    },
    {
        id: 7,
        text: "Goal Alignment",
        explanation: "The feedback aligned well with the goals of the task.",
        subExplanation: "Here, you're being asked if the feedback aligned with the goals of the task. Did the feedback help you understand how well you performed in relation to what the task was trying to achieve? Good feedback should directly connect to the task's objectives."
    },
    {
        id: 8,
        text: "Trustworthiness",
        explanation: "The feedback was trustworthy and unbiased.",
        subExplanation: "This statement asks whether the feedback felt fair, unbiased, and credible. Do you trust that the feedback was based on accurate information and provided an honest evaluation of your performance? Trustworthy feedback helps build confidence in the results."
    },
    {
        id: 9,
        text: "Cognitive Effort",
        explanation: "The feedback was requiring minimal effort to interpret.",
        subExplanation: "Consider whether the feedback required significant mental effort to process. Was it presented in a way that was intuitive and actionable, or did it feel challenging or overly complex to grasp?"
    }
]; 