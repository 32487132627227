import React from 'react';
import { MessageSquare } from 'lucide-react';
import { users, getUserIds } from '../constants/users';

const ChatHeader = ({
                        title = "Survival Items Discussion",
                        subtitle = "Live Chat Simulation"
                    }) => {
    return (
        <div className="flex items-center justify-between p-4 border-b bg-gray-50">
            <div className="flex items-center space-x-4">
                <div className="flex -space-x-2">
                    {getUserIds().map((userId) => (
                        <div key={userId} className="relative">
                            <div className={`
                w-8 h-8 rounded-full 
                flex items-center justify-center text-lg 
                ring-2 ring-white
                ${users[userId].color}
              `}>
                                {users[userId].avatar}
                            </div>

                            <div className={`
                absolute bottom-0 right-0 
                w-2.5 h-2.5 rounded-full 
                border-2 border-white
                ${users[userId].statusColor}
              `} />
                        </div>
                    ))}
                </div>

                <div>
                    <div className="flex items-center">
                        <MessageSquare className="w-4 h-4 mr-2 text-gray-600" />
                        <h2 className="font-semibold">{title}</h2>
                    </div>
                    <p className="text-sm text-gray-500">
                        {subtitle} • {getUserIds().length} participants
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ChatHeader;