import { useState, useEffect } from 'react';
import { readCSVFile } from '../utils/csvParser';

const useFileReader = (filePath) => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadFile = async () => {
            try {
                setLoading(true);
                const data = await readCSVFile(filePath);
                setMessages(data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setMessages([]);
            } finally {
                setLoading(false);
            }
        };

        loadFile();
    }, [filePath]);

    return { messages, loading, error };
};

export default useFileReader;