export const MESSAGE_TYPES = {
    REGULAR: 'regular',
    DECISION: 'decision',
    PRIVATE: 'private',
    SYSTEM: 'system'
};


export const getMessageType = (message) => {
    if (message.decision) return MESSAGE_TYPES.DECISION;
    if (message.private) return MESSAGE_TYPES.PRIVATE;
    return MESSAGE_TYPES.REGULAR;
};
