import Papa from 'papaparse';
import { convertTimeToMilliseconds, calculateMessageDelay } from './timeUtils';
import { getMessageType } from '../constants/messageTypes';

export const processCSVData = (csvData) => {
    return new Promise((resolve, reject) => {
        Papa.parse(csvData, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: (results) => {
                console.log('Parsed CSV data:', results.data[0]);

                if (!results.data || results.data.length === 0) {
                    reject(new Error('No data found in CSV'));
                    return;
                }

                const processedMessages = results.data
                    .filter(row => row && row.Participant)
                    .map((row, index, array) => {
                        console.log('Processing row:', row);

                        if (!row.Participant) {
                            console.warn('Missing Participant:', row);
                            return null;
                        }

                        const participantParts = row.Participant.split('.');
                        const user = participantParts[1]?.toLowerCase() || 'unknown';

                        const delay = index === 0
                            ? convertTimeToMilliseconds(row.Start)
                            : calculateMessageDelay(row.Start, array[index - 1].End);

                        return {
                            id: index,
                            user,
                            content: row.Sentence ? row.Sentence.replace(/^"|"$/g, '') : '',
                            startTime: row.Start,
                            endTime: row.End,
                            delay,
                            duration: convertTimeToMilliseconds(row.Duration),
                            type: getMessageType({
                                decision: row.Decision,
                                private: row.Private === 'Private'
                            }),
                            survivalItem: row['Survival Item'],
                            private: row.Private === 'Private'
                        };
                    })
                    .filter(msg => msg !== null);

                resolve(processedMessages);
            },
            error: (error) => {
                reject(new Error(`CSV parsing failed: ${error.message}`));
            }
        });
    });
};

export const readCSVFile = async (filePath) => {
    try {
        const response = await fetch(filePath);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const csvText = await response.text();
        console.log('CSV content preview:', csvText.slice(0, 200));

        const messages = await processCSVData(csvText);
        return messages;
    } catch (error) {
        console.error('Error reading or parsing CSV file:', error);
        throw error;
    }
};