import { useState, useEffect } from 'react';

const useFeedbackData = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadFeedback = async () => {
            try {
                const fileName = 'Individual_Feedback_1_Pink_1_20241209_192836.json';
                
                const response = await fetch(`/data/${fileName}`);
                const data = await response.json();
                
                const feedbackArray = data.individual_feedback;

                if (!feedbackArray || feedbackArray.length === 0) {
                    throw new Error('No individual feedback data available');
                }

                const allFeedbacks = feedbackArray.map(item => ({
                    prompt_number: item.prompt_number,
                    feedback: item.feedback
                }));

                allFeedbacks.sort((a, b) => a.prompt_number - b.prompt_number);
                
                setFeedbacks(allFeedbacks);
                setLoading(false);
            } catch (err) {
                setError('Failed to load individual feedback');
                setLoading(false);
            }
        };

        loadFeedback();
    }, []);

    return { feedbacks, loading, error };
};

export default useFeedbackData; 