import React, { useState, useEffect } from 'react';
import FeedbackEvaluation from './FeedbackEvaluation';
import TeamFeedbackEvaluation from './TeamFeedbackEvaluation';
import CompletionPage from './CompletionPage';
import FeedbackTutorial from './FeedbackTutorial';
import EvaluationIntroPage from './EvaluationIntroPage';
import { saveEvaluationToDatabase } from '../services/parseService';

const PromptEvaluationPage = ({ userName, loginId, score }) => {
    const [startEvaluation, setStartEvaluation] = useState(false);
    const [evaluationType, setEvaluationType] = useState(null);
    const [showIntro, setShowIntro] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);

    useEffect(() => {
        if (!loginId) {
            console.error('No login ID provided to PromptEvaluationPage');
        }
    }, [loginId]);

    const handleIndividualComplete = async (data) => {
        try {
            await saveEvaluationToDatabase(userName, loginId, 'individual', data);
            setEvaluationType('team');
            setStartEvaluation(false);
            setShowIntro(true);
        } catch (error) {
            if (error.message.includes('already submitted')) {
                alert('Great news! Your individual evaluation is already recorded. Let\'s move on to evaluating the team feedback.');
                setEvaluationType('team');
                setStartEvaluation(false);
                setShowIntro(true);
            } else {
                console.error('Error saving individual evaluation:', error);
                alert('Oops! We had a small hiccup saving your evaluation. Could you try that again?');
            }
        }
    };

    const handleTeamComplete = async (data) => {
        try {
            await saveEvaluationToDatabase(userName, loginId, 'team', data);
            setIsComplete(true);
        } catch (error) {
            if (error.message.includes('already submitted')) {
                alert('Excellent! Your team evaluation is already complete. Thank you for your participation!');
                setIsComplete(true);
            } else {
                console.error('Error saving team evaluation:', error);
                alert('Hmm, we had trouble saving your team evaluation. Mind giving it another try?');
            }
        }
    };

    if (isComplete) {
        return <CompletionPage userName={userName} />;
    }

    if (showTutorial) {
        return <FeedbackTutorial onComplete={() => {
            setShowTutorial(false);
            setEvaluationType('individual');
            setShowIntro(true);
        }} />;
    }

    if (showIntro) {
        return (
            <EvaluationIntroPage 
                type={evaluationType} 
                onStart={() => {
                    setShowIntro(false);
                    setStartEvaluation(true);
                }}
            />
        );
    }

    if (startEvaluation) {
        if (evaluationType === 'individual') {
            return <FeedbackEvaluation onComplete={handleIndividualComplete} />;
        }
        if (evaluationType === 'team') {
            return <TeamFeedbackEvaluation onComplete={handleTeamComplete} />;
        }
    }

    return (
        <div className="min-h-screen bg-gray-50 p-4">
            <div className="max-w-4xl mx-auto">
                <div className="bg-white rounded-xl shadow-lg p-8 mb-6">
                    <h1 className="text-3xl font-bold text-gray-900 mb-4">
                        Feedback Evaluation Study
                    </h1>
                    <div className="bg-green-50 border border-green-100 rounded-lg p-4 mb-6">
                        <p className="text-green-800">
                            Thank you <span className="font-semibold">{userName}</span> for participating in the survival discussion! 
                            Your team performed well, achieving a <span className="font-semibold">{score}%</span> match with expert rankings.
                        </p>
                    </div>
                    
                    <div className="bg-blue-50 p-6 rounded-lg border border-blue-100 mb-6">
                        <h2 className="text-xl font-semibold text-blue-900 mb-4">Study Overview</h2>
                        <div className="space-y-4">
                            <div>
                                <h3 className="font-medium text-blue-800 mb-2">What You'll Evaluate</h3>
                                <ul className="list-disc list-inside space-y-2 text-blue-700">
                                    <li>Two different types of AI-generated feedback:
                                        <ul className="list-none ml-6 mt-2 space-y-2">
                                            <li className="flex items-center">
                                                <span className="text-blue-500 mr-2">1.</span>
                                                Individual feedback about your personal contributions
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-blue-500 mr-2">2.</span>
                                                Team feedback about your group's collaboration
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            
                            <div>
                                <h3 className="font-medium text-blue-800 mb-2">Your Tasks</h3>
                                <ul className="list-disc list-inside space-y-2 text-blue-700">
                                    <li>For each type of feedback:
                                        <ul className="list-none ml-6 mt-2 space-y-2">
                                            <li className="flex items-center">
                                                <span>• Rate 9 different feedback examples</span>
                                            </li>

                                            <li className="flex items-center">
                                                <span>• Share your thoughts on the feedback's helpfulness</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={() => setShowTutorial(true)}
                        className="w-full bg-green-600 text-white py-3 px-6 rounded-lg
                                 hover:bg-green-700 transition-colors duration-200
                                 flex items-center justify-center gap-2"
                    >
                        Begin Feedback Evaluation
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PromptEvaluationPage; 