import React from 'react';
import { users } from '../constants/users';

const TypingIndicator = ({ user }) => {
    const userData = users[user] || {};

    return (
        <div className="flex items-center gap-3 opacity-75">
            <div className={`
                w-8 h-8 rounded-full 
                flex items-center justify-center text-sm
                shadow-md
                ${userData.color}
            `}>
                {userData.avatar}
            </div>
            <div className="bg-gradient-to-r from-gray-100 to-gray-50 rounded-full px-4 py-2 shadow-sm">
                <div className="flex items-center gap-2">
                    <span className="text-xs text-gray-500">{userData.name}</span>
                    <div className="flex space-x-1">
                        <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce" />
                        <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce [animation-delay:0.2s]" />
                        <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce [animation-delay:0.4s]" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TypingIndicator;