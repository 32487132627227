export const calculateAbsoluteDifference = (finalRankings, expertRankings) => {
    let differences = [];
    
    for (let item of finalRankings) {
        const userPos = finalRankings.indexOf(item);
        const expertPos = expertRankings.indexOf(item);
        const diff = Math.abs(userPos - expertPos);
        differences.push(diff);
    }
    
    return differences.reduce((sum, diff) => sum + diff, 0);
};

export const calculateScore = (absoluteDifference) => {
    const maxDifference = 105;
    absoluteDifference = Math.min(Math.max(absoluteDifference, 0), maxDifference);
    const score = 100 * (1 - (absoluteDifference / maxDifference));
    return Math.round(Math.max(0, Math.min(score, 100)));
}; 