import React, { useState } from 'react';
import Parse from 'parse';

const LoginPage = ({ onLogin }) => {
  const [loginId, setLoginId] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const trimmedId = loginId.trim();
      
      if (!trimmedId) {
        setError('Please enter a participant ID');
        return;
      }

      const AuthorizedUsers = Parse.Object.extend("AuthorizedUsers");
      const query = new Parse.Query(AuthorizedUsers);
      query.equalTo("loginId", trimmedId);
      
      let user = await query.first();
      
      if (!user) {
        user = new AuthorizedUsers();
        user.set("loginId", trimmedId);
        user.set("displayName", `${trimmedId}`);
        await user.save();
      }

      onLogin({
        id: trimmedId,
        displayName: user.get('displayName')
      });
      
    } catch (error) {
      console.error("Login error:", error);
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
      <div className="w-full max-w-sm p-6 bg-white rounded-xl shadow-lg text-center">
        <div className="mb-6">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">
            Team AI Feedback Study
          </h2>
          <p className="text-gray-600 mb-2 text-sm sm:text-base">
            Welcome to our research project on AI-generated feedback
          </p>
          <p className="text-xs sm:text-sm text-gray-500">
            You'll participate in a survival discussion scenario and evaluate different types of AI feedback
          </p>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg text-xs sm:text-sm">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="text"
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
              placeholder="Enter your Participant ID"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg 
                       focus:ring-2 focus:ring-green-500 focus:border-green-500
                       text-sm sm:text-base"
              disabled={isLoading}
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full py-2.5 px-4 bg-green-600 text-white rounded-lg 
                     hover:bg-green-700 transition-colors duration-200
                     text-sm sm:text-base font-medium
                     disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Logging in...' : 'Continue'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage; 