export const users = {
    pink: {
        name: 'Pink',
        avatar: '👩',
        color: 'bg-pink-100',
        textColor: 'text-pink-800',
        borderColor: 'border-pink-200',
        ringColor: 'ring-pink-400',
        hoverColor: 'hover:bg-pink-200',
        messageBg: 'bg-pink-50',
        messageText: 'text-pink-900',
        defaultStatus: 'online',
        statusColor: 'bg-green-500'
    },

    blue: {
        name: 'Blue',
        avatar: '👨',
        color: 'bg-blue-100',
        textColor: 'text-blue-800',
        borderColor: 'border-blue-200',
        ringColor: 'ring-blue-400',
        hoverColor: 'hover:bg-blue-200',
        messageBg: 'bg-blue-50',
        messageText: 'text-blue-900',
        defaultStatus: 'online',
        statusColor: 'bg-green-500'
    },

    green: {
        name: 'Green',
        avatar: '🧑',
        color: 'bg-green-100',
        textColor: 'text-green-800',
        borderColor: 'border-green-200',
        ringColor: 'ring-green-400',
        hoverColor: 'hover:bg-green-200',
        messageBg: 'bg-green-50',
        messageText: 'text-green-900',
        defaultStatus: 'online',
        statusColor: 'bg-green-500'
    }
};

export const getUserIds = () => Object.keys(users);