import Parse from 'parse';
import PARSE_CONFIG from '../config/parseConfig';

Parse.initialize(
    PARSE_CONFIG.applicationId,
    PARSE_CONFIG.javascriptKey
);

Parse.serverURL = PARSE_CONFIG.serverURL;

Parse._ajax = function(method, url, data, headers = {}) {
    headers = {
        ...headers,
        'Access-Control-Allow-Origin': '*',
        'X-Parse-Application-Id': PARSE_CONFIG.applicationId,
        'X-Parse-JavaScript-Key': PARSE_CONFIG.javascriptKey,
        'Content-Type': 'application/json'
    };
    
    return fetch(url, {
        method,
        headers,
        mode: 'cors',
        body: JSON.stringify(data)
    }).then(response => response.json());
};

const IndividualEvaluation = Parse.Object.extend("IndividualEvaluation");
const TeamEvaluation = Parse.Object.extend("TeamEvaluation");

const checkExistingSubmission = async (loginId, evaluationType) => {
    const EvaluationClass = evaluationType === 'individual' ? IndividualEvaluation : TeamEvaluation;
    const query = new Parse.Query(EvaluationClass);
    query.equalTo("loginId", loginId);
    
    const existing = await query.first();
    return existing !== undefined && existing !== null;
};

export const saveEvaluationToDatabase = async (userName, loginId, evaluationType, data) => {
    try {
        if (!loginId) {
            throw new Error('👋 Please log in again to continue with your evaluation');
        }

        const hasSubmitted = await checkExistingSubmission(loginId, evaluationType);
        if (hasSubmitted) {
            throw new Error(` Thanks! We already have your ${evaluationType} evaluation recorded. Let's move on to the next step.`);
        }

        const EvaluationClass = evaluationType === 'individual' ? IndividualEvaluation : TeamEvaluation;
        const evaluation = new EvaluationClass();

        evaluation.set("loginId", loginId.toLowerCase().trim());
        evaluation.set("submissionId", `${loginId.toLowerCase().trim()}_${evaluationType}_${Date.now()}`);
        evaluation.set("timestamp", new Date());

        const AuthorizedUsers = Parse.Object.extend("AuthorizedUsers");
        const query = new Parse.Query(AuthorizedUsers);
        query.equalTo("loginId", loginId.toLowerCase().trim());
        const authorizedUser = await query.first();
        
        if (authorizedUser) {
            evaluation.set("user", authorizedUser);
        }

        evaluation.set("userName", userName);
        
        const structuredData = {
            metadata: {
                version: "1.0",
                evaluationType,
                totalPrompts: data.ratings.length,
                averageRating: calculateAverageRating(data.ratings),
                submissionComplete: true,
                timestamp: new Date().toISOString()
            },
            ratings: data.ratings,
            feedback: {
                generalComment: data.generalComment || "",
                hasComment: !!data.generalComment
            }
        };

        evaluation.set("evaluationData", structuredData);
        const result = await evaluation.save();
        return result;
    } catch (error) {
        console.error('Save error details:', error);
        throw error;
    }
};

const calculateAverageRating = (ratings) => {
    if (!Array.isArray(ratings) || ratings.length === 0) return 0;
    
    let total = 0;
    let count = 0;
    
    ratings.forEach(prompt => {
        prompt.responses.forEach(response => {
            if (response.rating) {
                total += parseInt(response.rating);
                count++;
            }
        });
    });
    
    return count > 0 ? parseFloat((total / count).toFixed(2)) : 0;
};

export const checkUserSubmissions = async (loginId) => {
    try {
        if (!loginId) return { hasIndividual: false, hasTeam: false, isComplete: false };

        const individualQuery = new Parse.Query(IndividualEvaluation);
        individualQuery.equalTo("loginId", loginId.toLowerCase().trim());
        
        const teamQuery = new Parse.Query(TeamEvaluation);
        teamQuery.equalTo("loginId", loginId.toLowerCase().trim());
        
        const [individualResult, teamResult] = await Promise.all([
            individualQuery.first(),
            teamQuery.first()
        ]);

        const hasIndividual = individualResult !== undefined && individualResult !== null;
        const hasTeam = teamResult !== undefined && teamResult !== null;

        return {
            hasIndividual,
            hasTeam,
            isComplete: hasIndividual && hasTeam
        };
    } catch (error) {
        console.error('Error checking submissions:', error);
        return { hasIndividual: false, hasTeam: false, isComplete: false };
    }
}; 