import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { IoInformationCircleOutline } from 'react-icons/io5';

const TUTORIAL_STEPS = [
    {
        title: "Welcome to the Feedback Evaluation",
        content: "Let's walk through how to evaluate feedback effectively.",
        details: [
            "You'll evaluate multiple feedback items one at a time",
            "Each feedback must be read completely before rating",
            "A timer ensures you spend adequate time on each feedback"
        ],
        example: {
            type: "preview",
            component: (
                <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 mt-4">
                    <div className="flex items-center justify-between mb-2">
                        <h3 className="font-medium">Feedback #1</h3>
                        <div className="text-blue-600">Time Remaining: 45s</div>
                    </div>
                    <p className="text-gray-600 text-sm">
                        Click "Show More" to read the complete feedback...
                    </p>
                </div>
            )
        }
    },
    {
        title: "Reading the Feedback",
        content: "Important steps for evaluating each feedback:",
        details: [
            "1. Click 'Show More' to expand the full feedback",
            "2. Timer will start only after expanding the feedback",
            "3. You must wait for the timer to complete before rating",
            "4. Use this time to read and understand the feedback thoroughly"
        ],
        example: {
            type: "expand",
            component: (
                <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 mt-4">
                    <div className="flex justify-between items-center">
                        <button className="text-blue-600">Show More</button>
                        <span className="text-green-600">Time Remaining: 30s</span>
                    </div>
                </div>
            )
        }
    },
    {
        title: "Rating Process",
        content: "How to evaluate the feedback using the criteria:",
        details: [
            "Clarity and Understanding, Satisfaction, Level of Detail, Completeness, Accuracy and Reliability, Actionability, Goal Alignment, Trustworthiness, Cognitive Effort",
            "Click the 'More info' button under each criterion for detailed explanation"
        ],
        example: {
            type: "rating",
            component: (
                <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 mt-4">
                    <div className="flex items-start gap-2 mb-4">
                        <div>
                            <p className="font-medium">Completeness</p>
                            <p className="text-sm text-gray-600 mt-1">
                                The feedback covered all relevant aspects of the task
                            </p>
                            <button className="text-blue-600 hover:text-blue-700 mt-2 flex items-center gap-1">
                                <IoInformationCircleOutline size={18} />
                                <span className="text-sm">More info</span>
                            </button>
                        </div>
                    </div>
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-50">
                                {["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"].map((label, i) => (
                                    <th key={i} className="px-4 py-2 text-center text-sm">
                                        {label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {[1, 2, 3, 4, 5].map(value => (
                                    <td key={value} className="px-4 py-2 text-center">
                                        <input
                                            type="radio"
                                            disabled
                                            className="w-4 h-4 text-gray-300 cursor-not-allowed"
                                        />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <div className="text-center text-sm text-gray-500 mt-3">
                        Rating options will be enabled after reading the feedback
                    </div>
                </div>
            )
        }
    },
    {
        title: "Moving to Next Feedback",
        content: "Requirements for proceeding:",
        details: [
            "All criteria must be rated before proceeding",
            "The timer must complete before ratings are enabled",
            "Optional comments can be added at the bottom",
            "Click 'Submit Evaluation' when ready for the next feedback"
        ],
        example: {
            type: "navigation",
            component: (
                <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 mt-4">
                    <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600">Feedback 1 of 9</span>
                        <button className="px-4 py-2 bg-gray-200 text-gray-500 rounded">
                            Please wait...
                        </button>
                    </div>
                </div>
            )
        }
    }
];

const FeedbackTutorial = ({ onComplete }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const handleNext = () => {
        if (currentStep < TUTORIAL_STEPS.length - 1) {
            setCurrentStep(prev => prev + 1);
        } else {
            onComplete();
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 p-4">
            <div className="max-w-4xl mx-auto">
                <div className="bg-white rounded-xl shadow-lg p-8">
                    <h1 className="text-3xl font-bold text-gray-900 mb-6">
                        How to Evaluate The Feedback
                    </h1>
                    
                    <div className="mb-8">
                        <div className="flex justify-between mb-4">
                            {TUTORIAL_STEPS.map((_, index) => (
                                <div
                                    key={index}
                                    className={`h-2 flex-1 mx-1 rounded ${
                                        index <= currentStep ? 'bg-green-500' : 'bg-gray-200'
                                    }`}
                                />
                            ))}
                        </div>
                        
                        <motion.div
                            key={currentStep}
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -20 }}
                            className="bg-blue-50 p-6 rounded-lg border border-blue-100"
                        >
                            <h2 className="text-xl font-semibold text-blue-900 mb-4">
                                {TUTORIAL_STEPS[currentStep].title}
                            </h2>
                            <p className="text-blue-800 mb-4">
                                {TUTORIAL_STEPS[currentStep].content}
                            </p>
                            <ul className="space-y-3">
                                {TUTORIAL_STEPS[currentStep].details.map((detail, index) => (
                                    <li key={index} className="flex items-center text-blue-700">
                                        <span className="mr-2">•</span>
                                        {detail}
                                    </li>
                                ))}
                            </ul>
                            {TUTORIAL_STEPS[currentStep].example?.component && (
                                <div className="mt-6">
                                    <p className="text-sm text-blue-600 mb-2">Example:</p>
                                    {TUTORIAL_STEPS[currentStep].example.component}
                                </div>
                            )}
                        </motion.div>
                    </div>

                    <div className="flex justify-between">
                        <button
                            onClick={() => setCurrentStep(prev => Math.max(0, prev - 1))}
                            className={`px-6 py-2 rounded-lg ${
                                currentStep === 0
                                    ? 'invisible'
                                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                            }`}
                        >
                            Previous
                        </button>
                        <button
                            onClick={handleNext}
                            className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                        >
                            {currentStep === TUTORIAL_STEPS.length - 1 ? 'Start Evaluation' : 'Next'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackTutorial; 